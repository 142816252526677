<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="colorSnak"
    >
      {{ text }}
      <v-btn
        color="black"
        text
        @click="snackbar = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>
    <v-row class="fill-height">
      <v-col>
        <v-sheet height="64">
          <v-toolbar
            flat
            color="white"
          >
            <v-btn
              outlined
              class="mr-4"
              color="grey darken-2"
              @click="setToday"
            >
              Hoy
            </v-btn>
            <v-btn
              fab
              text
              small
              color="grey darken-2"
              @click="prev"
            >
              <v-icon small>
                mdi-chevron-left
              </v-icon>
            </v-btn>
            <v-btn
              fab
              text
              small
              color="grey darken-2"
              @click="next"
            >
              <v-icon small>
                mdi-chevron-right
              </v-icon>
            </v-btn>
            <v-col
              cols="4"
            >
              <v-select
                v-if="notario"
                v-model="notarioModel"
                :items="notarios"
                item-text="FullName"
                item-value="id"
                dense
                outlined
                hide-details
                label="Notario"
                @input="findAgendaByIdUSer"
              />
            </v-col>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-spacer />
            <v-dialog
              v-model="dialog"
              max-width="800px"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  class="mx-2"
                  v-on="on"
                >
                  Restricciones
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">Restricciones</span>
                </v-card-title>

                <v-card-text>
                  <v-form
                    ref="form"
                    v-model="valid"
                    :lazy-validation="lazy"
                  >
                    <v-container>
                      <v-row>
                        <v-switch
                          v-model="lunes"
                          class="col-3"
                          label="Lunes"
                          @change="addDay($event, 1)"
                        />
                        <v-switch
                          v-model="martes"
                          class="col-3"
                          label="Martes"
                          @change="addDay($event, 2)"
                        />
                        <v-switch
                          v-model="miercoles"
                          class="col-3"
                          label="Miércoles"
                          @change="addDay($event, 3)"
                        />
                        <v-switch
                          v-model="jueves"
                          value="4"
                          class="col-3"
                          label="Jueves"
                          @change="addDay($event, 4)"
                        />
                      </v-row>
                      <v-row>
                        <v-switch
                          v-model="viernes"
                          class="col-4"
                          label="Viernes"
                          @change="addDay($event, 5)"
                        />
                        <v-switch
                          v-model="sabados"
                          class="col-4"
                          label="Sábados"
                          @change="addDay($event, 6)"
                        />
                        <v-switch
                          v-model="domingos"
                          class="col-4"
                          label="Domingos"
                          @change="addDay($event, 0)"
                        />
                      </v-row>
                      <v-row>
                        <v-col
                          cols="12"
                          sm="12"
                          md="6"
                        >
                          <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="time"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="time"
                                label="Inicio Día"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              />
                            </template>
                            <v-time-picker
                              v-if="menu"
                              v-model="time"
                              full-width
                              @click:minute="$refs.menu.save(time)"
                            />
                          </v-menu>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="6"
                        >
                          <v-menu
                            ref="menu1"
                            v-model="menu1"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="time1"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="time1"
                                label="Fin Día"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              />
                            </template>
                            <v-time-picker
                              v-if="menu1"
                              v-model="time1"
                              full-width
                              @click:minute="$refs.menu1.save(time1)"
                            />
                          </v-menu>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="12"
                          sm="12"
                          md="6"
                        >
                          <v-menu
                            ref="menu2"
                            v-model="menu2"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="time2"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="time2"
                                label="Inicio Tarde"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              />
                            </template>
                            <v-time-picker
                              v-if="menu2"
                              v-model="time2"
                              full-width
                              @click:minute="$refs.menu2.save(time2)"
                            />
                          </v-menu>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="6"
                        >
                          <v-menu
                            ref="menu3"
                            v-model="menu3"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="time3"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="time3"
                                label="Fin Tarde"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              />
                            </template>
                            <v-time-picker
                              v-if="menu3"
                              v-model="time3"
                              full-width
                              @click:minute="$refs.menu3.save(time3)"
                            />
                          </v-menu>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="12"
                          sm="12"
                          md="6"
                        >
                          <v-menu
                            ref="menuFn"
                            v-model="menuFn"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="editedItem.newDate"
                                label="Día"
                                persistent-hint
                                prepend-icon="mdi-calendar-month"
                                readonly
                                append-outer-icon="mdi-plus"
                                @click:append-outer="addDate"
                                @blur="date = parseDate(editedItem.newDate)"
                                v-on="on"
                              />
                            </template>
                            <v-date-picker
                              v-model="date"
                              locale="es"
                              show-current="false"
                              @input="menuFn = false"
                            />
                          </v-menu>
                          <v-card
                            class="mx-auto"
                            max-width="90%"
                            raised
                          >
                            <v-list subheader>
                              <v-subheader>Fechas restringidas</v-subheader>
                              <v-divider />
                              <v-list-item
                                v-for="(item, i) in editedItem.dates"
                                :key="i"
                              >
                                <v-list-item-content>
                                  <v-list-item-title v-text="item.dia" />
                                </v-list-item-content>

                                <v-list-item-action>
                                  <v-btn icon>
                                    <v-icon
                                      color="error"
                                      @click="removeDate(i, item.id)"
                                    >
                                      mdi-delete
                                    </v-icon>
                                  </v-btn>
                                </v-list-item-action>
                              </v-list-item>
                            </v-list>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                  >
                    {{ $t("close") }}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="save"
                  >
                    {{ $t("save") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-menu
              bottom
              right
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  outlined
                  color="grey darken-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <span>{{ (typeToLabel[type] == "Month")? "Mes" : "Día" }}</span>
                  <v-icon right>
                    mdi-menu-down
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Día</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Mes</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <agenda-component
              v-if="dialogAgenda"
              :id="id"
              :visible="dialogAgenda"
              @close="dialogAgenda = false"
            />
          </v-toolbar>
        </v-sheet>
        <v-sheet height="600">
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            locale="es"
            :dark="true"
            :events="events"
            :event-color="getEventColor"
            :type="type"
            :first-interval="intervals.first"
            :interval-minutes="intervals.minutes"
            :interval-count="intervals.count"
            :interval-height="intervals.height"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewDay"
          />
          <!-- @change="updateRange" -->
          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
          >
            <v-card
              color="grey lighten-4"
              min-width="350px"
              flat
            >
              <v-toolbar
                :color="selectedEvent.color"
                dark
              >
                <v-btn
                  icon
                  @click="showAgenda"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-toolbar-title v-html="selectedEvent.name" />
                <v-spacer />
                <!-- <v-btn icon>
                  <v-icon>mdi-heart</v-icon>
                </v-btn>
                <v-btn icon>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn> -->
              </v-toolbar>
              <v-card-text>
                Inicio: <span v-html="selectedEvent.start" /><br>
                Fin: <span v-html="selectedEvent.end" /><br>
                Tiempo Sala: <span v-html="selectedEvent.tiempoSala" /><br>
                Tiempo Notario: <span v-html="selectedEvent.tiempoNotario" /><br>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  text
                  color="secondary"
                  @click="selectedOpen = false"
                >
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import AgendaApi from '@/services/api/Agenda'
  import UsuariosApi from '@/services/api/Usuarios'
  import EventBus from '../../../bus'

  export default {
    name: 'AdminAgenda',

    components: {
      AgendaComponent: () => import('@/views/dashboard/component/Agenda'),
    },

    data: vm => {
      return {
        date: '',
        valid: true,
        snackbar: false,
        colorSnak: 'info',
        text: '',
        timeout: 3000,
        lazy: false,
        dialog: false,
        dialogAgenda: false,
        id: '',
        time: null,
        time1: null,
        time2: null,
        time3: null,
        menu: false,
        menu1: false,
        menu2: false,
        menu3: false,
        menuFn: false,
        lunes: false,
        martes: false,
        miercoles: false,
        jueves: false,
        viernes: false,
        sabados: false,
        domingos: false,
        lunesID: '',
        martesID: '',
        miercolesID: '',
        juevesID: '',
        viernesID: '',
        sabadosID: '',
        domingosID: '',
        focus: '',
        type: 'month',
        typeToLabel: {
          month: 'Month',
          day: 'Day',
        },
        intervals: {
          first: 16,
          minutes: 30,
          count: 20,
          height: 48,
        },
        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,
        events: [],
        notarios: [],
        notario: false,
        notarioModel: '',
        colors: ['blue'],
        editedItem: {
          id: '',
          dates: [],
          newdate: vm.formatDate(new Date().toISOString().substr(0, 10)),
        },
        defaultItem: {
          id: '',
          dates: [],
          newdate: vm.formatDate(new Date().toISOString().substr(0, 10)),
        },
      }
    },
    watch: {
      dialog (val) {
        val || this.close()
      },
      date (val) {
        this.editedItem.newDate = this.formatDate(val)
      },
    },
    created () {
      this.initialize()
    },
    mounted () {
      this.$refs.calendar.checkChange()
    },
    methods: {
      // showIntervalLabel (interval) {
      //   return interval.minute === 0
      // },
      formatDate (date) {
        if (!date) return null
        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
      parseDate (date) {
        if (!date) return null
        const [day, month, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
      initialize () {
        this.findAgendaByNotario()
        EventBus.$on('update-agenda', () => {
          this.events = []
          this.findAgendaByNotario()
        })
      },
      findAgendaByNotario () {
        if (localStorage.getItem('role') === 'ROLE_NOTARIO') {
          AgendaApi.getAgendaByEmail()
            .then(response => {
              this.id = response.usuario
              this.editedItem.id = response.id
              this.editedItem.dates = response.dates
              this.time = response.inicio_dia
              this.time1 = response.fin_dia
              this.time2 = response.inicio_tarde
              this.time3 = response.fin_tarde
              response.days.forEach(element => {
                switch (element.days) {
                  case '0':
                    this.domingos = true
                    this.domingosID = element.id
                    break
                  case '1':
                    this.lunes = true
                    this.lunesID = element.id
                    break
                  case '2':
                    this.martes = true
                    this.martesID = element.id
                    break
                  case '3':
                    this.miercoles = true
                    this.miercolesID = element.id
                    break
                  case '4':
                    this.jueves = true
                    this.juevesID = element.id
                    break
                  case '5':
                    this.viernes = true
                    this.viernesID = element.id
                    break
                  case '6':
                    this.sabados = true
                    this.sabadosID = element.id
                    break
                }
              })
              this.updateEvents(response.citas)
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          if (this.notarioModel !== '') {
            this.findAgendaByIdUSer(this.notarioModel)
          } else {
            UsuariosApi.getAllNotarios()
              .then(response => {
                this.notarios = response
                this.notario = true
                if (response.length === 1) {
                  this.notarioModel = response[0].id
                  this.findAgendaByIdUSer(this.notarioModel)
                }
              })
              .catch(error => {
                console.log(error)
              })
          }
        }
      },
      findAgendaByIdUSer (i) {
        AgendaApi.getAgendaByIdUser(i)
          .then(response => {
            this.id = response.usuario
            this.editedItem.id = response.id
            this.editedItem.dates = response.dates
            this.time = response.inicio_dia
            this.time1 = response.fin_dia
            this.time2 = response.inicio_tarde
            this.time3 = response.fin_tarde
            response.days.forEach(element => {
              switch (element.days) {
                case '0':
                  this.domingos = true
                  this.domingosID = element.id
                  break
                case '1':
                  this.lunes = true
                  this.lunesID = element.id
                  break
                case '2':
                  this.martes = true
                  this.martesID = element.id
                  break
                case '3':
                  this.miercoles = true
                  this.miercolesID = element.id
                  break
                case '4':
                  this.jueves = true
                  this.juevesID = element.id
                  break
                case '5':
                  this.viernes = true
                  this.viernesID = element.id
                  break
                case '6':
                  this.sabados = true
                  this.sabadosID = element.id
                  break
              }
            })
            this.updateEvents(response.citas)
          })
          .catch(error => {
            console.log(error)
          })
      },
      viewDay ({ date }) {
        this.focus = date
        this.type = 'day'
      },
      addDay (event, id) {
        var item = ''
        switch (id) {
          case 0:
            item = this.domingosID
            break
          case 1:
            item = this.lunesID
            break
          case 2:
            item = this.martesID
            break
          case 3:
            item = this.miercolesID
            break
          case 4:
            item = this.juevesID
            break
          case 5:
            item = this.viernesID
            break
          case 6:
            item = this.sabadosID
            break
        }
        const payload = {
          agenda: this.editedItem.id,
          days: event ? id : '',
          id: item,
        }

        AgendaApi.restriccionDays(payload)
          .then(response => {
            switch (response.days) {
              case '0':
                this.domingos = true
                this.domingosID = response.id
                break
              case '1':
                this.lunes = true
                this.lunesID = response.id
                break
              case '2':
                this.martes = true
                this.martesID = response.id
                break
              case '3':
                this.miercoles = true
                this.miercolesID = response.id
                break
              case '4':
                this.jueves = true
                this.juevesID = response.id
                break
              case '5':
                this.viernes = true
                this.viernesID = response.id
                break
              case '6':
                this.sabados = true
                this.sabadosID = response.id
                break
            }

            this.text = 'Registro creado correctamente'
            this.colorSnak = 'success'
            this.snackbar = true
          })
          .catch(error => {
            console.log(error)
            this.text = 'Lo sentimos, el registro no puede ser eliminado en este momento'
            this.colorSnak = 'error'
            this.snackbar = true
          })
      },
      getEventColor (event) {
        return event.color
      },
      setToday () {
        this.focus = ''
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
      showEvent ({ nativeEvent, event }) {
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          setTimeout(() => {
            this.selectedOpen = true
          }, 10)
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          setTimeout(open, 10)
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      },
      showAgenda () {
        if (this.dialogAgenda) {
          this.dialogAgenda = false
        } else {
          this.dialogAgenda = true
        }
      },
      updateEvents (citas) {
        const events = []

        for (let i = 0; i < citas.length; i++) {
          const first = String(citas[i].inicio.date).substring(0, 16)
          const second = String(citas[i].fin.date).substring(0, 16)
          events.push({
            name: citas[i].procedimiento,
            start: first,
            end: second,
            tiempoSala: citas[i].tiempo_sala,
            tiempoNotario: citas[i].tiempo_notario,
            color: this.colors[this.rnd(0, this.colors.length - 1)],
            timed: true,
          })
        }

        this.events = events
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
      close () {
        this.dialog = false
        setTimeout(() => {
          // this.editedItem = Object.assign({}, this.defaultItem)
          // this.editedIndex = -1
        }, 300)
      },
      save () {
        const data = {
          id: this.editedItem.id,
          inicio_dia: this.time,
          fin_dia: this.time1,
          inicio_tarde: this.time2,
          fin_tarde: this.time3,
        }

        AgendaApi.updateAgenda(data)
          .then(response => {
            this.time = response.inicio_dia
            this.time1 = response.fin_dia
            this.time2 = response.inicio_tarde
            this.time3 = response.fin_tarde
            this.text = 'Registro modificado correctamente'
            this.snackbar = true
            this.colorSnak = 'success'
            this.close()
          })
          .catch(error => {
            console.log(error)
            this.text = 'El registro no puede ser modificado en este momento'
            this.colorSnak = 'error'
            this.snackbar = true
          })
      },
      addDate () {
        const payload = {
          agenda: this.editedItem.id,
          dia: this.editedItem.newDate,
        }
        console.log('payload', payload)

        AgendaApi.restriccionDate(payload)
          .then(response => {
            this.editedItem.dates.push({
              id: response.id,
              dia: this.editedItem.newDate,
            })
            this.editedItem.newDate = ''

            this.text = 'Registro creado correctamente'
            this.colorSnak = 'success'
            this.snackbar = true
          })
          .catch(error => {
            console.log(error)
            this.text = 'Lo sentimos, el registro no puede ser eliminado en este momento'
            this.colorSnak = 'error'
            this.snackbar = true
          })
      },

      removeDate (i, item) {
        const index = i
        if (confirm('Realmente desea eliminar este registro?')) {
          AgendaApi.deleteRestriccionDate(item)
            .then(response => {
              this.editedItem.dates.splice(index, 1)
              this.text = 'Registro eliminado correctamente'
              this.colorSnak = 'success'
              this.snackbar = true
            })
            .catch(error => {
              console.log(error)
              this.text = 'Lo sentimos, el registro no puede ser eliminado en este momento'
              this.colorSnak = 'error'
              this.snackbar = true
            })
        }
      },
    },
  }
</script>
